import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function CardEvaluation({
  politician,
  manifestCompliance,
  activeness,
  matchYourPref,
  disclosureFrequency,
  readability,
}) {
  const data = {
    labels: [
      'マニフェスト遵守', // manifestCompliance
      '活動の積極性', // activeness
      'あなたの関心分野との整合性', // match
      '自主的な情報開示の頻度', // disclosureFrequency
      '情報伝達のわかりやすさ', // readability
    ],
    datasets: [
      {
        label: '2022年5月11日',
        data: [
          Math.round(manifestCompliance),
          Math.round(activeness),
          50, // NOT IMPLEMENTED
          Math.round(disclosureFrequency),
          Math.round(readability),
        ],
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
      {
        label: '平均',
        data: [50, 50, 50, 50, 50],
        // fill: true,
        // backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        borderColor: 'rgb(120, 120, 120)',
        pointBackgroundColor: 'rgb(120, 120, 120)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(120, 120, 120)',
        borderDash: [6, 3],
      },
    ],
  };

  const options = {
    responsive: true,
    scale: {
      min: 0,
      max: 100,
      stepSize: 20,
    },
  };

  return (
    <>
      {/* https://tailwind-elements.com/docs/standard/components/cards/ */}
      <div className="flex justify-center">
        <div className="flex w-full flex-col rounded-lg bg-white shadow-lg">
          <div className="rounded-t mb-0 p-2 bg-transparent">
            <h6 className="pl-2 md:min-w-full text-blueGray-500 text-sm uppercase font-bold block no-underline">
              Evaluation Graph
            </h6>
          </div>

          <div className="pb-6">
            <Radar data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}
