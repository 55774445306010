import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
  PointElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
};

const darkBlue = 'rgb(68, 84, 106)';
const lightBlue = 'rgb(130, 150, 176)';
const darkOrange = 'rgb(244, 176, 129)';
const lightOrange = 'rgb(247, 202, 172)';
const jiminAverage = 1.98571428571429;

const getData = (score) => {
  const cfg_mean = 293.96666666666664;
  const cfg_std = 332.09624334812696;

  const data = {
    labels: [
      '活動記録（最新活動）',
      '活動記録（詳細）',
      '刊行物',
      'ブログ',
      '取材対応',
      'Twitter',
      'Facebook',
      'LINE',
      'YouTube',
      'Instagram',
      'その他',
    ],
    datasets: [
      {
        type: 'bar',
        label: '情報開示の頻度',
        data: [
          score.activity_log,
          score.activity_log_detail,
          score.publication,
          score.blog_post,
          score.interview,
          score.twitter,
          score.facebook,
          score.line,
          score.youtube,
          score.instagram,
          score.others,
        ],
        backgroundColor: darkBlue,
        stack: 'Stack 0',
      },
    ],
  };
  return data;
};

export default function CardDisclosureFreq({ score }) {
  if (score == null) {
    return <></>;
  }
  console.log('score');
  console.log(score);
  const data = getData(score);
  return (
    <>
      {/* https://tailwind-elements.com/docs/standard/components/cards/ */}
      <div className="flex justify-center">
        <div className="flex w-full flex-col rounded-lg bg-white shadow-lg">
          <div className="p-2">
            <h6 className="pl-2 md:min-w-full text-blueGray-500 text-base uppercase font-bold block no-underline">
              自主的な情報開示の頻度
            </h6>
          </div>

          <div className="xs:p-6">
            {/* Summary */}
            {/* <Chart data={summaryData} options={summaryOptions} /> */}
            {/* Details */}
            {/* <div className="grow">
              <Chart data={data} options={options} />
            </div> */}
            <div>
              <Chart data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
