import React from 'react';
import ModalCommittees from 'components/ModalCommittees';
import { SocialIcon } from 'react-social-icons';

const sns_properties = [
  'personal_web',
  'publication',
  'blog',
  'twitter',
  'facebook',
  'line',
  'youtube',
  'instagram',
];

export default function CardProfile(props) {
  const sns_icons = sns_properties.map((x, i) =>
    props[x] != null ? (
      <SocialIcon
        key={`social-icon-${i}`}
        url={props[x]}
        style={{ height: 25, width: 25, marginRight: '4px' }}
      />
    ) : null
  );

  return (
    <>
      {/* https://tailwind-elements.com/docs/standard/components/cards/ */}
      <div className="flex justify-center">
        <div className="flex w-full flex-row flex-wrap rounded-lg bg-white shadow-lg p-2">
          <div className="flex md:w-4/12 items-center justify-center">
            <img
              className="w-28 h-28 md:w-36 md:h-36 pl-2 object-cover"
              src={props.picture}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-start p-3 md:w-8/12">
            {/* Name */}
            <div>
              <h5 className="text-gray-900 text-xl font-medium">
                {`${props.last_name || ''} ${props.first_name || ''}`}
              </h5>
              <span className="text-gray-500 font-bold text-sm">
                {`${props.last_kana || ''} ${props.first_kana || ''}`}
              </span>
            </div>
            {/* Topics of interest */}
            <div>
              {props.topics_of_interest?.map((x, i) => (
                <span
                  key={`topic-badge-${i}`}
                  className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                >
                  {x.name}
                </span>
              ))}
            </div>
            {/* Details */}
            <div className="p-1">
              <ul className="text-base">
                <li>{props.congress}</li>
                <li>会派: {props.kaiha}</li>
                <li>選挙区: {props.district}</li>
                <li>
                  所属委員会数
                  <ModalCommittees committees={props.committees} />:{' '}
                  {props.committees.length}
                </li>
              </ul>
            </div>
            {/* Social Media */}
            <div>{sns_icons}</div>
            <div className="mt-2">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-5 py-1 rounded grow-0">
                {Math.round(props.score)}点
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardProfile.defaultProps = {
  last_name: 'Yamada',
  first_name: 'Taro',
  picture: 'https://placehold.jp/150x150.png',
  kaiha: 'XX会派',
  district: 'N/A',
  yakusyoku: ['役職A', '役職B'],
  score: 50,
  committees: [],
};

// CardStats.propTypes = {
//   statSubtitle: PropTypes.string,
//   statTitle: PropTypes.string,
//   statArrow: PropTypes.oneOf(["up", "down"]),
//   statPercent: PropTypes.string,
//   // can be any of the text color utilities
//   // from tailwindcss
//   statPercentColor: PropTypes.string,
//   statDescripiron: PropTypes.string,
//   statIconName: PropTypes.string,
//   // can be any of the background color utilities
//   // from tailwindcss
//   statIconColor: PropTypes.string,
// };
