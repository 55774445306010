import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
  PointElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
  Legend,
  annotationPlugin
);

const darkBlue = 'rgb(68, 84, 106)';
const darkOrange = 'rgb(244, 176, 129)';
const averageScore = 50;

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      max: 100,
    },
  },
  plugins: {
    autocolors: false,
    annotation: {
      annotations: {
        line1: {
          type: 'line',
          yMin: averageScore,
          yMax: averageScore,
          borderColor: '#565656',
          borderWidth: 2,
        },
      },
    },
  },
};

const getData = (score) => {
  const barData = [
    score.sentence_length_score,
    score.kango_score,
    score.wago_score,
    score.verb_score,
    score.auxiliary_score,
  ];

  const data = {
    labels: ['平均文長', '漢語率', '和語率', '動詞率', '助詞率'],

    datasets: [
      {
        type: 'line',
        label: '平均',
        data: Array(5).fill(averageScore),
        borderColor: '#565656',
        backgroundColor: '#565656',
        pointRadius: 0,
      },
      {
        type: 'bar',
        label: score.name,
        data: barData,
        backgroundColor: barData.map((x) =>
          x > averageScore ? darkBlue : darkOrange
        ),
      },
    ],
  };
  return data;
};

export default function CardReadability({ score }) {
  if (score == null) {
    return <></>;
  }
  const data = getData(score);
  return (
    <>
      {/* https://tailwind-elements.com/docs/standard/components/cards/ */}
      <div className="flex justify-center">
        <div className="flex w-full flex-col rounded-lg bg-white shadow-lg">
          <div className="p-2">
            <h6 className="pl-2 md:min-w-full text-blueGray-500 text-base uppercase font-bold block no-underline">
              情報伝達のわかりやすさ
            </h6>
          </div>

          <div className="xs:p-6">
            <Chart data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}
