import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useHistory } from 'react-router-dom';
import React from 'react';

export default function PoliticianList({ politicians }) {
  document.title = 'PoliGov';
  const history = useHistory();

  const handleOnSelect = (item) => {
    console.log('handle on select');
    history.push(`/politicians/${item.pid}`);
  };

  return (
    <>
      {/* <div className="relative bg-sky-600 md:pt-32 pb-32 pt-12"> */}
      <div className="relative md:pt-32 pb-32 pt-12">
        <div className="px-1 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="px-4 pb-4">
              <ReactSearchAutocomplete
                items={politicians}
                onSelect={handleOnSelect}
                placeholder="政治家を検索"
              />{' '}
            </div>
            {/* <div className="flex flex-wrap gap-5 bg-white">ABC XXX</div> */}
          </div>
        </div>
      </div>
    </>
  );
}
