import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/tailwind.css';
// import 'assets/styles/index.css';

// layouts

import Politician from 'layouts/Politician.js';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/politicians" component={Politician} />
      <Redirect from="*" to="/politicians" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
