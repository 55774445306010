import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
  PointElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
  Legend,
  annotationPlugin
);

const darkBlue = 'rgb(68, 84, 106)';
const lightBlue = 'rgb(130, 150, 176)';
const darkOrange = 'rgb(244, 176, 129)';
const lightOrange = 'rgb(247, 202, 172)';
const jiminAverage = 1.98571428571429;

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      max: 3.5,
    },
  },
  plugins: {
    autocolors: false,
    annotation: {
      annotations: {
        line1: {
          type: 'line',
          yMin: jiminAverage,
          yMax: jiminAverage,
          borderColor: '#565656',
          borderWidth: 2,
        },
      },
    },
  },
};

const getData = (score) => {
  const data = {
    labels: score.map((x) => x.topic_name),
    datasets: [
      {
        type: 'line',
        label: '自民党平均',
        data: Array(score.length).fill(jiminAverage),
        borderColor: '#565656',
        backgroundColor: '#565656',
        pointRadius: 0,
      },
      {
        type: 'bar',
        label: '具体性',
        data: score.map((x) => x.measurable),
        backgroundColor: score.map((x) =>
          x.measurable > jiminAverage ? darkBlue : darkOrange
        ),
      },
      {
        type: 'bar',
        label: '達成度',
        data: score.map((x) => x.achievement),
        backgroundColor: score.map((x) =>
          x.achievement > jiminAverage ? lightBlue : lightOrange
        ),
        stack: 'Stack 0',
      },
    ],
  };
  return data;
};

export default function CardManifestCompliance({ score }) {
  if (!Array.isArray(score)) {
    return <></>;
  }
  const data = getData(score);
  return (
    <>
      {/* https://tailwind-elements.com/docs/standard/components/cards/ */}
      <div className="flex justify-center">
        <div className="flex w-full flex-col rounded-lg bg-white shadow-lg">
          <div className="p-2">
            <h6 className="pl-2 md:min-w-full text-blueGray-500 text-base uppercase font-bold block no-underline">
              マニフェスト遵守（自民党）
            </h6>
          </div>

          <div className="xs:p-6">
            <Chart data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}
