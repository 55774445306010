import CardEvaluation from 'components/Cards/CardEvaluation';
import CardProfile from 'components/Cards/CardProfile';
import CardManifestCompliance from 'components/Cards/CardManifestCompliance';
import CardDisclosureFreq from 'components/Cards/CardDisclosureFreq';
import CardReadability from 'components/Cards/CardReadability';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useParams, useHistory } from 'react-router-dom';

import React from 'react';
import { useState, useEffect } from 'react';

export default function PoliticianProfile({ politicians }) {
  // get politician
  const getPolitician = async (pid) => {
    try {
      const response = await fetch(`/api/v1/politicians/${pid}`);
      const data = await response.json();

      const eval_response = await fetch(
        `/api/v1/politicians/${pid}/evaluation`
      );
      const eval_data = await eval_response.json();

      data['id'] = data['pid'];
      data['name'] = (data['last_name'] || '') + (data['first_name'] || '');
      data['evaluation'] = eval_data;

      setPolitician(data);

      document.title = `PoliGov - ${data['name']}`;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { pid } = useParams();
  const history = useHistory();
  const [politician, setPolitician] = useState();

  useEffect(() => {
    getPolitician(pid);
  }, [pid]);

  const handleOnSelect = (item) => {
    console.log('handle on select');
    history.push(`/politicians/${item.pid}`);
  };

  return (
    <>
      {/* <div className="relative bg-sky-600 md:pt-32 pb-32 pt-12"> */}
      <div className="relative md:pt-32 pb-32 pt-12">
        <div className="px-1 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="px-4 pb-4">
              <ReactSearchAutocomplete
                items={politicians}
                onSelect={handleOnSelect}
                placeholder="政治家を検索"
                fuseOptions={{
                  keys: ['first_name', 'last_name', 'first_kana', 'last_kana'],
                }}
              />{' '}
            </div>
            <div className="flex flex-wrap">
              <div className="w-full 2xl:w-1/2">
                <div className="py-2 px-1 xs:p-4">
                  <CardProfile
                    {...politician}
                    score={politician?.evaluation?.score?.average}
                  />
                </div>
              </div>
              <div className="w-full 2xl:w-1/2">
                <div className="py-2 px-1 xs:p-4">
                  <CardEvaluation
                    politician={politician}
                    activeness={politician?.evaluation?.score?.activeness}
                    readability={politician?.evaluation?.score?.readability}
                    manifestCompliance={
                      politician?.evaluation?.score?.manifest_compliance
                    }
                    disclosureFrequency={
                      politician?.evaluation?.score?.disclosure_freq
                    }
                  />
                </div>
              </div>
              <div className="w-full 2xl:w-1/2">
                <div className="py-2 px-1 xs:p-4">
                  <CardManifestCompliance
                    score={politician?.evaluation?.details?.manifest_compliance}
                  />
                </div>
              </div>
              <div className="w-full 2xl:w-1/2">
                <div className="py-2 px-1 xs:p-4">
                  <CardDisclosureFreq
                    score={politician?.evaluation?.details?.disclosure_freq}
                  />
                </div>
              </div>
              <div className="w-full 2xl:w-1/2">
                <div className="py-2 px-1 xs:p-4">
                  <CardReadability
                    score={politician?.evaluation?.details?.readability}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
