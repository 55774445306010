import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// components

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import FooterAdmin from 'components/Footers/FooterAdmin.js';

// views

import PoliticianList from 'views/politician/PoliticianList.js';
import PoliticianProfile from 'views/politician/PoliticianProfile.js';

export default function Politician() {
  const [politicians, setPoliticians] = useState();

  // get all politicians
  const getPoliticians = async () => {
    try {
      const response = await fetch('/api/v1/politicians');
      const jsonData = await response.json();

      jsonData.forEach((item) => {
        item['id'] = item['pid'];
        item['name'] = (item['last_name'] || '') + (item['first_name'] || '');
      });

      setPoliticians(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getPoliticians();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-500">
        <AdminNavbar />

        <Switch>
          {/* <Route path="/politicians/:pid">
            <PoliticianProfile politicians} />
          </Route> */}
          {politicians ? (
            <>
              <Route path="/politicians" exact>
                <PoliticianList politicians={politicians} />
              </Route>
              <Route path="/politicians/:pid">
                <PoliticianProfile politicians={politicians} />
              </Route>
            </>
          ) : (
            <></>
          )}
        </Switch>
        <FooterAdmin />
      </div>
    </>
  );
}
